.footer {
  background-color: #f8f8f8;
  height: 10vh;
  max-height: 100px;
  display: grid;
  margin-top: 5vh;
  border-top: 1px solid #dcdcdc;
  padding: 0 2% 0 2%;
  grid-template-columns: 1fr 3fr 0.8fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "logo  links right";
}

.left-logo-footer {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  grid-area: logo;
}

.main-links {
  grid-area: links;
  display: flex;
  font-size: 0.9rem;
  margin-left: 15%;
  justify-content: space-evenly;
  align-items: center;
  border-right: 1px solid #dcdcdc;
}

.right-link-footer {
  grid-area: right;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

@media only screen and (max-width: 768px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "links"
      "right";
  }
  .left-logo-footer {
    display: none;
  }
  .main-links {
    grid-area: links;
    border-right: 0;
    margin: 0 0 10px 0;
    justify-content: space-evenly;
  }
}
