.card-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
  min-height: 55vh;
  text-align: center;
}

.wrapper-div {
  font-family: inherit;
  padding: 20px 50px 45px 50px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.title-login {
  font-weight: 700;
  font-size: 1.2rem;
}

.letter-spacing-2 {
  letter-spacing: 2px;
  background-color: #f8f8f8;
}

.mrg-5 {
  margin-top: 5px;
}

@media only screen and (max-width: 768px) {
  .wrapper-div {
    padding: 0;
    background-color: #f8f8f8;
    box-shadow: none;
  }
}
