.not-found {
  margin-top: 11vh;
  color: #636262;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "left right"
    "btn btn";
  font-family: "proxima-nova";
}

.content-404 {
  min-height: 59vh;
}
.left-section {
  display: flex;
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: 5px;
  justify-content: center;
  align-items: center;
  grid-area: left;
}

.span {
  display: none;
}
.right-section {
  border-left: 2px solid gray;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  grid-area: right;
}
.p0 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
}

.btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7vh;
}
.p1 {
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.p2 {
  font-size: 1.25rem;
  letter-spacing: 0;
  color: gray;
}

@media only screen and (max-width: 768px) {
  .not-found {
    display: flex;
    flex-direction: column;
  }
  .span {
    display: inline;
  }

  .left-section {
    display: none;
  }
  .p0 {
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .p1 {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  .p2 {
    font-size: 1rem;
    letter-spacing: 0;
    color: gray;
  }

  .right-section {
    margin-left: 5%;
  }
}
