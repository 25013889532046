.home {
  font-size: calc(10px + 2vmin);
  width: 60%;
  margin: 3vh auto 0 auto;
}

.label-main {
  font-size: 1rem;
  margin-right: 5px;
}

.main-content {
  min-height: 67vh;
}
.cntrls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-url {
  font-family: inherit;
  border: none;
  outline: none;
  font-size: 1.2rem;
  width: 96%;
  letter-spacing: 1px;
  background: none;
}

.clear-btn {
  display: inline;
  cursor: pointer;
}

.input-style {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 6px 15px;
  color: rgb(82, 80, 80);
  background: white;
  outline: none;
  margin-bottom: 10px;
}

.input-vanity {
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 20px;
  padding: 5px 10px;
  color: rgb(82, 80, 80);
  outline: none;
  width: 50%;
}

.error {
  border: 1px solid red;
  color: red;
  background: rgba(255, 233, 233, 0.92);
}

.controls {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  animation: myani 0.2s;
  animation-timing-function: ease-in;
}
@keyframes myani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.span-bold {
  font-weight: 700;
}
.ttl {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.tnt {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px 0;
}
.checkbox-style {
  width: 50%;
  background-color: white;
}

.vanity {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.left-div {
  display: inline-flex;
  align-items: center;
}

.ttl-right {
  width: 50%;
  display: inline-flex;
  justify-content: space-between;
}

.ttl-chkbox {
  width: 45%;
  background-color: white;
}

.input-ttl {
  border: 1px solid gray;
  background-color: white;
  border-radius: 5px;
  font-size: 20px;
  padding: 5px 10px;
  color: rgb(82, 80, 80);
  width: 53%;
  outline: none;
  letter-spacing: 3px;
}
.advanced-link {
  font-size: 16px;
}

.msg-mrg {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

.hlink {
  color: #003e69;
  font-weight: 600;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .home {
    width: 90%;
  }

  .ttl {
    margin-top: 15px;
  }

  .ttl-div {
    width: 65%;
  }
}

@media only screen and (max-width: 450px) {
  .controls {
    width: 90%;
  }
}
