.my-header,
.my-header-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  padding: 2vh;
  width: 90%;
  height: 13vh;
  max-height: 140px;
  border-bottom: 1px solid #dcdcdc;
}

.brand-logo {
  cursor: pointer;
  width: 220px;
}
.logout-btn {
  position: absolute;
  /* top: 10vh; */
  right: 7vw;
}

@media only screen and (max-width: 768px) {
  .logout-btn {
    position: unset;
  }
  .brand-logo {
    width: 170px;
  }
  .my-header-1 {
    justify-content: space-between;
    width: 100%;
  }
}
