.App {
  text-align: center;
}

.message-inline {
  word-break: inherit;
}

.content {
  background-color: #f8f8f8;
  display: flex;
  min-height: 68vh;
  margin-top: 2vh;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}
.error-text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
}

.otp-ui {
  margin-top: 2vh;
  padding: 50px 60px 45px 60px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.form-field-text-length-indicator {
  padding-right: 0;
  font-size: 13px;
  padding-left: 0.5rem;
}

.err-msg {
  display: inline-flex;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

.small-text {
  font-size: 0.9rem;
}

.expiration-msg {
  color: #ea0000;
}

.link-expired {
  margin-top: 25vh;
  border-left: 10px solid #ea0000;
  padding: 20px 30px 20px 15px;
  box-shadow: 5px 5px 10px 0px #a9a9a9;
}

.loadin-spinner {
  margin-top: 15vh;
}

@media only screen and (max-width: 768px) {
  .content {
    width: 85%;
    margin: 0 auto;
  }
  .notification-div {
    top: 15vh;
  }
  .otp-ui {
    padding: 35px 35px;
  }
  .link-expired {
    padding: 15px;
  }
}
